<template>
  <b-card>
    <b-row>
      <b-col sm="12">
        <html-content
          api="https://api.vestaorganic.ru/api/v1/htm/app/service/page/link/confidenceBinar"
        />
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'

import HtmlContent from '@core/components/html-content/HtmlContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    HtmlContent,
  },
}
</script>
